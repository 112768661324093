import LienHolderService from "@/services/lienholder.service";

const lienHolderService = new LienHolderService();

export default {
  name: 'lien-holders',
  components: {},
  props: [],
  data() {
    return {
      localImage: null,
      file: null,
      lienHolderList: [],
      lienHolder: {Name: ''},
      lienHolderCreateModal: false,
    }
  },
  computed: {},
  async mounted() {
    await this.getLienHolderList();
  },
  methods: {
    async getLienHolderList() {
      const res = await lienHolderService.list('', '');
      if (res && !res.error) {
        this.lienHolderList = res.data.lienHolders;
      }
    },
    async Create() {
      const formData = new FormData();
      formData.append('Name', this.lienHolder.Name);
      formData.append('file', this.file);

      const res = await lienHolderService.create(formData);

      if (res && !res.error) {
        await this.getLienHolderList();
        this.lienHolderCreateModal = false;
      }
    },

    async Remove(lienHolderId) {
      await lienHolderService.remove(lienHolderId);
    },

    onSelectedImage(event) {
      const file = event.target.files[0];
      if (!file) {
        this.localImage = null;
        this.file = null;
        return;
      }

      this.file = file;

      const fr = new FileReader();
      fr.onload = () => this.localImage = fr.result;
      fr.readAsDataURL(file);
    },

    lienHolderImageURL(lienHolder) {
      return this.$s3_url() + 'lienHolder/' + lienHolder;
    },

    closeCreateModal() {
      const resetForm = {
        Name: '',
      }
      this.localImage = null;
      this.file = null;
      this.lienHolder = resetForm;
      this.lienHolderCreateModal = false;
    }
  }
}


